import React from 'react'
import Header from '../../header/header'
import Footer from '../../footer/footer'
import "./trauma.css"

const Trauma = () => {
  return (
    <>
   
    <Header />
    <section className='trauma_wrap'>
<p> 
Emergency and Trauma: Understanding the Critical Difference
Emergency medicine and trauma care are closely related fields, both dealing with urgent and life-threatening situations. However, they have distinct focuses and approaches:


</p>

<ul><p>Emergency Medicine:  </p>

  <li> <p> Focus: Stabilizing and treating a wide range of acute illnesses and injuries, regardless of cause.</p> </li>

  <li> <p> Scope: Covers non-trauma emergencies like severe allergic reactions, heart attacks, strokes, respiratory distress, poisoning, and more. </p></li>
<li> <p> Settings: Emergency rooms (ERs), urgent care centers, ambulances.  </p> </li>

<li>  <p> Approach: Often involves rapid diagnosis, administration of medications, and initial treatment to stabilize the patient before further evaluation or specialized care. </p> </li>


</ul>


<ul> <p> Trauma Care:  </p> <li> 
  
  <p> Focus: Treating severe bodily injuries, often caused by accidents, violence, or falls.</p>
  <p>  Scope: Primarily deals with injuries to bones, muscles, organs, and blood vessels.  </p>
    </li>
    
    <li> <p>Settings: Trauma centers within hospitals, specialized ambulances.  </p> </li>

    <li> <p> Approach: Employs multidisciplinary teams of surgeons, nurses, anesthesiologists, and other specialists working together to quickly assess, stabilize, and manage the patient's injuries. Requires specialized protocols and equipment to handle potentially life-threatening situations.</p> </li>
      </ul>
    </section>
      <Footer />
     
    </>
  )
}

export default Trauma
