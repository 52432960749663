import React from 'react'
import Footer from '../../footer/footer'
import Header from '../../header/header'
import "./internalmedicine.css"

const Internalmedicine = () => {
  return (
    <>
    <Header />
    <section className='internalmedicine_wrap'>

    <p>Internal medicine, also known as general internal medicine is that discipline of drugs that deals with the diagnosis and treatment of diseases of the interior organs without any involvement of surgery. Our general physicians consider the entire area of patient’s health and well being when providing care, including the emotional, psychological or physical effects of the patient’s condition and treatment. We have an extensive range of data on medical conditions and consider overall well-being when providing treatment. Our team is highly competent and senior consultants provide a range of services in the field of general medicine. All of our doctors care for patients with difficult, serious, unusual medical problems and continue to provide care to patients until these problems have stabilised or resolved. Our physicians give vivid communication to make sure that patients understand their diagnosis and are able to manage it appropriately without feeling stressed. </p>

    </section>
   
    <Footer />
    </>
  )
}

export default Internalmedicine
