import React from 'react'
import Header from '../../header/header'
import Footer from '../../footer/footer'
import "./ortho.css"
const Ortho = () => {
  return (
    <>
      <Header />
      <section className='ortho_wrap'>
        <p>Rao Lokram Charitable Trust Hospital is the best orthopedic hospital in Gurgaon, is a full-fledged and sophisticated unit of orthopedics consisting of a strong operating team. Backed by one of the best intensive care units and other allied specialties, a laminar airflow enabled operating theatre, operating microscopes, image intensifiers, arthroscopes, power driver instruments, trained and dedicated personnel – this unit gained the reputation of the capability to manage serious polytrauma apart from isolated injuries and has been serving the society for many years.</p>
     <ul> 
      <li><p>The department for several years now has kept abreast with the field of joint replacement surgery and has a number of patients benefited by total knee, hip, elbow, and shoulder replacement surgeries including revision joint surgeries. The department also has one of the most competent arthroscopy units rendering keyhole surgeries of the joints a possibility and reality.</p> </li>
     <li> <p>The department also boasts of a full-fledged unit for Ilizarov which caters to limb lengthening, intercalary loss of bone, difficult malunions / non-unions. </p> </li>
     <li> <p>The department has also competently and successfully been engaged in surgeries of the spine including disc-prolapse, disc replacement, canal stenosis, fractures of the spine including fixation and fusion, kyphoplasty, apart from other surgeries of the spine. </p></li>
     <li> <p>The unit also caters to congenital deformities of the skeletal system including CTEV, CDH, and various other disorders.</p> </li>
     <li> <p>The department also has to its credit numerous soft tissue surgeries including nerve and tendon repair and re-routing, stabilization of joints, treatment of poliomyelitis, and cerebral palsy. </p></li>
     
     </ul>
     
      </section>
      <Footer />
    </>
  )
}

export default Ortho
