import React from 'react'
import Header from '../../header/header'
import Footer from '../../footer/footer'
import "./diabetology.css"

const Diabetology = () => {
  return (
    <>
      <Header />
      <section className='diabetology_wrap'>
        <p>Diabetology is a branch of medicine that specializes in the diagnosis, management, and treatment of diabetes mellitus, a chronic metabolic condition characterized by elevated blood sugar levels. Diabetologists are medical professionals who have received specialized training and certification in the field of diabetes care.

          Here's a deeper dive into diabetology:

        </p>
        <p>  What do diabetologists do?  </p>

        <ul>  <li><p> Diagnose diabetes: They perform various tests to assess blood sugar levels, identify the type of diabetes, and evaluate potential complications</p> </li>

          <li> <p> Develop treatment plans: This may involve lifestyle modifications, medication management, insulin therapy, and education on self-care practices.  </p> </li>

          <li>   <p>Monitor and manage diabetes: They regularly monitor blood sugar levels, adjust medications as needed, and address any complications that arise.  </p></li>

          <li>  <p> Provide education and support: Diabetologists educate patients about their condition, empower them to manage their diabetes effectively, and offer emotional support throughout their journey. </p></li>
        </ul>

        <p> Subspecialties in diabetology:</p>

        <ul>  <li>   <p>   Pediatric diabetology: Focuses on diabetes in children and adolescents.</p> </li>

          <li> <p> Endocrinology: Deals with hormonal imbalances, including those related to diabetes.</p> </li>

          <li>  <p> Nephrology: Manages diabetes-related kidney complications. </p></li>
          <li> <p>Cardiology: Addresses cardiovascular risks associated with diabetes. </p></li>
        </ul>

        <p> Importance of diabetology:</p>
        <ul>

          <li> <p> Diabetes is a global health issue affecting millions worldwide.</p> </li>

          <li><p> Early diagnosis and proper management are crucial to prevent serious complications like heart disease, stroke, kidney failure, and blindness. </p> </li>
          <li>  <p> Diabetologists play a vital role in helping individuals with diabetes live long, healthy, and fulfilling lives. </p></li>
        </ul>
      </section>

      <Footer />
    </>
  )
}

export default Diabetology
