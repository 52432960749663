import React from 'react'
import Header from '../../header/header'
import Footer from '../../footer/footer'
import "./laboratory.css"
const Laboratory = () => {
  return (
    <>
      <Header />
      <section className='laboratory_wrap'>
        <p> A laboratory, also known as a lab, is a facility designed for conducting scientific experiments, research, and measurement. Laboratories are found in a wide variety of settings, including schools, universities, research institutions, companies, government agencies, and hospitals. They are typically equipped with specialized equipment and instrumentation that allows scientists to perform a wide range of tasks, such as: </p>

        <ul>  <li>  <p>   Analyzing samples of materials </p> </li>

          <li>  <p>Measuring physical and chemical properties  </p></li>
          <li>   <p> Testing hypotheses  </p></li>

          <li>  <p>  Developing new technologies </p></li>

          <li>  <p> Creating new materials </p></li>

        </ul>

        <p>Laboratories play an essential role in scientific research and development. They provide the environment and resources that scientists need to make new discoveries and develop new technologies that improve our lives.  </p>
      </section>
      <Footer />
    </>
  )
}

export default Laboratory
